<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${$i18n.t('siteTitle')}` : `${$i18n.t('siteTitle')}`
  },
  script: [
    {
      src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
    }
  ]
})

useSeoMeta({
  ogImage: '/WH-displayimage-150ppi.jpg'
})
</script>

<template>
  <div id="app">
    <NuxtLayout>
      <NavigationLoadingIndicatorComponent />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
