import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrow, LazySvgoClockHour, LazySvgoClockMinute, LazySvgoClockNumbers, LazySvgoClockOutline, LazySvgoClockSecond, LazySvgoCross, LazySvgoEnvelopNew01, LazySvgoEnvelopNew02, LazySvgoEnvelop, LazySvgoFloorplanFirstFloor, LazySvgoFloorplanGroundFloor, LazySvgoFloorplanSecondFloor, LazySvgoLogo } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrow", LazySvgoArrow],
["SvgoClockHour", LazySvgoClockHour],
["SvgoClockMinute", LazySvgoClockMinute],
["SvgoClockNumbers", LazySvgoClockNumbers],
["SvgoClockOutline", LazySvgoClockOutline],
["SvgoClockSecond", LazySvgoClockSecond],
["SvgoCross", LazySvgoCross],
["SvgoEnvelopNew01", LazySvgoEnvelopNew01],
["SvgoEnvelopNew02", LazySvgoEnvelopNew02],
["SvgoEnvelop", LazySvgoEnvelop],
["SvgoFloorplanFirstFloor", LazySvgoFloorplanFirstFloor],
["SvgoFloorplanGroundFloor", LazySvgoFloorplanGroundFloor],
["SvgoFloorplanSecondFloor", LazySvgoFloorplanSecondFloor],
["SvgoLogo", LazySvgoLogo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
