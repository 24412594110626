import { default as _91slug_93S0js3RtOm6Meta } from "/src/pages/[slug].vue?macro=true";
import { default as aboutaC0FMxDxqnMeta } from "/src/pages/about.vue?macro=true";
import { default as _91slug_93QwsqhQupsBMeta } from "/src/pages/fellows/[slug].vue?macro=true";
import { default as index0arDmmFolyMeta } from "/src/pages/fellows/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as page_45previewqdX5u0GUuHMeta } from "/src/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue?macro=true";
import { default as _91slug_9334rOK54k9SMeta } from "/src/pages/permanent-works/[slug].vue?macro=true";
import { default as permanent_45workskFLUPtJOABMeta } from "/src/pages/permanent-works.vue?macro=true";
import { default as _91secondary_45slug_93v6KXicLgETMeta } from "/src/pages/program/[slug]/[secondary-slug].vue?macro=true";
import { default as _91slug_93mLLmdOOiXEMeta } from "/src/pages/program/[slug].vue?macro=true";
import { default as indexTJt0ZMHSd1Meta } from "/src/pages/program/index.vue?macro=true";
import { default as _91slug_93QylLN5L3FMMeta } from "/src/pages/publications/[slug].vue?macro=true";
import { default as publicationsHxVsMKkCuyMeta } from "/src/pages/publications.vue?macro=true";
export default [
  {
    name: _91slug_93S0js3RtOm6Meta?.name ?? "slug___en",
    path: _91slug_93S0js3RtOm6Meta?.path ?? "/:slug()",
    meta: _91slug_93S0js3RtOm6Meta || {},
    alias: _91slug_93S0js3RtOm6Meta?.alias || [],
    redirect: _91slug_93S0js3RtOm6Meta?.redirect || undefined,
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S0js3RtOm6Meta?.name ?? "slug___nl",
    path: _91slug_93S0js3RtOm6Meta?.path ?? "/nl/:slug()",
    meta: _91slug_93S0js3RtOm6Meta || {},
    alias: _91slug_93S0js3RtOm6Meta?.alias || [],
    redirect: _91slug_93S0js3RtOm6Meta?.redirect || undefined,
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutaC0FMxDxqnMeta?.name ?? "about___en",
    path: aboutaC0FMxDxqnMeta?.path ?? "/about",
    meta: aboutaC0FMxDxqnMeta || {},
    alias: aboutaC0FMxDxqnMeta?.alias || [],
    redirect: aboutaC0FMxDxqnMeta?.redirect || undefined,
    component: () => import("/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutaC0FMxDxqnMeta?.name ?? "about___nl",
    path: aboutaC0FMxDxqnMeta?.path ?? "/nl/over",
    meta: aboutaC0FMxDxqnMeta || {},
    alias: aboutaC0FMxDxqnMeta?.alias || [],
    redirect: aboutaC0FMxDxqnMeta?.redirect || undefined,
    component: () => import("/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QwsqhQupsBMeta?.name ?? "fellows-slug___en",
    path: _91slug_93QwsqhQupsBMeta?.path ?? "/fellows/:slug()",
    meta: _91slug_93QwsqhQupsBMeta || {},
    alias: _91slug_93QwsqhQupsBMeta?.alias || [],
    redirect: _91slug_93QwsqhQupsBMeta?.redirect || undefined,
    component: () => import("/src/pages/fellows/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QwsqhQupsBMeta?.name ?? "fellows-slug___nl",
    path: _91slug_93QwsqhQupsBMeta?.path ?? "/nl/fellows/:slug()",
    meta: _91slug_93QwsqhQupsBMeta || {},
    alias: _91slug_93QwsqhQupsBMeta?.alias || [],
    redirect: _91slug_93QwsqhQupsBMeta?.redirect || undefined,
    component: () => import("/src/pages/fellows/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0arDmmFolyMeta?.name ?? "fellows___en",
    path: index0arDmmFolyMeta?.path ?? "/fellows",
    meta: index0arDmmFolyMeta || {},
    alias: index0arDmmFolyMeta?.alias || [],
    redirect: index0arDmmFolyMeta?.redirect || undefined,
    component: () => import("/src/pages/fellows/index.vue").then(m => m.default || m)
  },
  {
    name: index0arDmmFolyMeta?.name ?? "fellows___nl",
    path: index0arDmmFolyMeta?.path ?? "/nl/fellows",
    meta: index0arDmmFolyMeta || {},
    alias: index0arDmmFolyMeta?.alias || [],
    redirect: index0arDmmFolyMeta?.redirect || undefined,
    component: () => import("/src/pages/fellows/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___en",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___nl",
    path: indexvsa0KwJWqiMeta?.path ?? "/nl",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: page_45previewqdX5u0GUuHMeta?.name ?? "page-preview",
    path: page_45previewqdX5u0GUuHMeta?.path ?? "/page-preview",
    meta: page_45previewqdX5u0GUuHMeta || {},
    alias: page_45previewqdX5u0GUuHMeta?.alias || [],
    redirect: page_45previewqdX5u0GUuHMeta?.redirect || undefined,
    component: () => import("/src/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue").then(m => m.default || m)
  },
  {
    name: permanent_45workskFLUPtJOABMeta?.name ?? "permanent-works___en",
    path: permanent_45workskFLUPtJOABMeta?.path ?? "/permanent-works",
    children: [
  {
    name: _91slug_9334rOK54k9SMeta?.name ?? "permanent-works-slug___en",
    path: _91slug_9334rOK54k9SMeta?.path ?? "/permanent-works/:slug",
    meta: _91slug_9334rOK54k9SMeta || {},
    alias: _91slug_9334rOK54k9SMeta?.alias || [],
    redirect: _91slug_9334rOK54k9SMeta?.redirect || undefined,
    component: () => import("/src/pages/permanent-works/[slug].vue").then(m => m.default || m)
  }
],
    meta: permanent_45workskFLUPtJOABMeta || {},
    alias: permanent_45workskFLUPtJOABMeta?.alias || [],
    redirect: permanent_45workskFLUPtJOABMeta?.redirect || undefined,
    component: () => import("/src/pages/permanent-works.vue").then(m => m.default || m)
  },
  {
    name: permanent_45workskFLUPtJOABMeta?.name ?? "permanent-works___nl",
    path: permanent_45workskFLUPtJOABMeta?.path ?? "/nl/permanente-werken",
    children: [
  {
    name: _91slug_9334rOK54k9SMeta?.name ?? "permanent-works-slug___nl",
    path: _91slug_9334rOK54k9SMeta?.path ?? "/nl/permanente-werken/:slug",
    meta: _91slug_9334rOK54k9SMeta || {},
    alias: _91slug_9334rOK54k9SMeta?.alias || [],
    redirect: _91slug_9334rOK54k9SMeta?.redirect || undefined,
    component: () => import("/src/pages/permanent-works/[slug].vue").then(m => m.default || m)
  }
],
    meta: permanent_45workskFLUPtJOABMeta || {},
    alias: permanent_45workskFLUPtJOABMeta?.alias || [],
    redirect: permanent_45workskFLUPtJOABMeta?.redirect || undefined,
    component: () => import("/src/pages/permanent-works.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mLLmdOOiXEMeta?.name ?? "program-slug___en",
    path: _91slug_93mLLmdOOiXEMeta?.path ?? "/programme/:slug",
    children: [
  {
    name: _91secondary_45slug_93v6KXicLgETMeta?.name ?? "program-slug-secondaryslug___en",
    path: _91secondary_45slug_93v6KXicLgETMeta?.path ?? "/programme/:slug/:secondaryslug",
    meta: _91secondary_45slug_93v6KXicLgETMeta || {},
    alias: _91secondary_45slug_93v6KXicLgETMeta?.alias || [],
    redirect: _91secondary_45slug_93v6KXicLgETMeta?.redirect || undefined,
    component: () => import("/src/pages/program/[slug]/[secondary-slug].vue").then(m => m.default || m)
  }
],
    meta: _91slug_93mLLmdOOiXEMeta || {},
    alias: _91slug_93mLLmdOOiXEMeta?.alias || [],
    redirect: _91slug_93mLLmdOOiXEMeta?.redirect || undefined,
    component: () => import("/src/pages/program/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mLLmdOOiXEMeta?.name ?? "program-slug___nl",
    path: _91slug_93mLLmdOOiXEMeta?.path ?? "/nl/programma/:slug",
    children: [
  {
    name: _91secondary_45slug_93v6KXicLgETMeta?.name ?? "program-slug-secondaryslug___nl",
    path: _91secondary_45slug_93v6KXicLgETMeta?.path ?? "/nl/programma/:slug/:secondaryslug",
    meta: _91secondary_45slug_93v6KXicLgETMeta || {},
    alias: _91secondary_45slug_93v6KXicLgETMeta?.alias || [],
    redirect: _91secondary_45slug_93v6KXicLgETMeta?.redirect || undefined,
    component: () => import("/src/pages/program/[slug]/[secondary-slug].vue").then(m => m.default || m)
  }
],
    meta: _91slug_93mLLmdOOiXEMeta || {},
    alias: _91slug_93mLLmdOOiXEMeta?.alias || [],
    redirect: _91slug_93mLLmdOOiXEMeta?.redirect || undefined,
    component: () => import("/src/pages/program/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTJt0ZMHSd1Meta?.name ?? "program___en",
    path: indexTJt0ZMHSd1Meta?.path ?? "/programme",
    meta: indexTJt0ZMHSd1Meta || {},
    alias: indexTJt0ZMHSd1Meta?.alias || [],
    redirect: indexTJt0ZMHSd1Meta?.redirect || undefined,
    component: () => import("/src/pages/program/index.vue").then(m => m.default || m)
  },
  {
    name: indexTJt0ZMHSd1Meta?.name ?? "program___nl",
    path: indexTJt0ZMHSd1Meta?.path ?? "/nl/programma",
    meta: indexTJt0ZMHSd1Meta || {},
    alias: indexTJt0ZMHSd1Meta?.alias || [],
    redirect: indexTJt0ZMHSd1Meta?.redirect || undefined,
    component: () => import("/src/pages/program/index.vue").then(m => m.default || m)
  },
  {
    name: publicationsHxVsMKkCuyMeta?.name ?? "publications___en",
    path: publicationsHxVsMKkCuyMeta?.path ?? "/publications",
    children: [
  {
    name: _91slug_93QylLN5L3FMMeta?.name ?? "publications-slug___en",
    path: _91slug_93QylLN5L3FMMeta?.path ?? "/publications/:slug",
    meta: _91slug_93QylLN5L3FMMeta || {},
    alias: _91slug_93QylLN5L3FMMeta?.alias || [],
    redirect: _91slug_93QylLN5L3FMMeta?.redirect || undefined,
    component: () => import("/src/pages/publications/[slug].vue").then(m => m.default || m)
  }
],
    meta: publicationsHxVsMKkCuyMeta || {},
    alias: publicationsHxVsMKkCuyMeta?.alias || [],
    redirect: publicationsHxVsMKkCuyMeta?.redirect || undefined,
    component: () => import("/src/pages/publications.vue").then(m => m.default || m)
  },
  {
    name: publicationsHxVsMKkCuyMeta?.name ?? "publications___nl",
    path: publicationsHxVsMKkCuyMeta?.path ?? "/nl/publicaties",
    children: [
  {
    name: _91slug_93QylLN5L3FMMeta?.name ?? "publications-slug___nl",
    path: _91slug_93QylLN5L3FMMeta?.path ?? "/nl/publicaties/:slug",
    meta: _91slug_93QylLN5L3FMMeta || {},
    alias: _91slug_93QylLN5L3FMMeta?.alias || [],
    redirect: _91slug_93QylLN5L3FMMeta?.redirect || undefined,
    component: () => import("/src/pages/publications/[slug].vue").then(m => m.default || m)
  }
],
    meta: publicationsHxVsMKkCuyMeta || {},
    alias: publicationsHxVsMKkCuyMeta?.alias || [],
    redirect: publicationsHxVsMKkCuyMeta?.redirect || undefined,
    component: () => import("/src/pages/publications.vue").then(m => m.default || m)
  }
]