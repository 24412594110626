<template>
  <div
    :class="$style.root"
    :style="{
      opacity: isLoading ? 1 : 0,
      'background-size': `${(100 / progress) * 100}% auto`,
      transform: `scaleX(${progress}%)`,
    }"
  />
</template>

<script setup lang="ts">
import { globalMiddleware } from '#build/middleware'
const nuxtApp = useNuxtApp()
const router = useRouter()

const props = defineProps({
  throttle: {
    type: Number,
    default: 100
  },
  duration: {
    type: Number,
    default: 500
  },
  height: {
    type: Number,
    default: 3
  }
})

const generateRouteKey = (route: RouteLocationNormalized) => {
  const source = route?.meta.key ?? route.path
    .replace(/(:\w+)\([^)]+\)/g, '$1')
    .replace(/(:\w+)[?+*]/g, '$1')
    .replace(/:\w+/g, r => route.params[r.slice(1)]?.toString() || '')
  return typeof source === 'function' ? source(route) : source
}

const isChangingPage = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (to === from) { return false }

  // If route keys are different then it will result in a rerender
  if (generateRouteKey(to) !== generateRouteKey(from)) { return true }

  const areComponentsSame = to.matched.every((comp, index) =>
    comp.components && comp.components.default === from.matched[index]?.components?.default
  )
  if (areComponentsSame) {
    return false
  }
  return true
}

const useLoadingIndicator = (opts: {
  duration: number,
  throttle: number
}) => {
  const progress = ref(0)
  const isLoading = ref(false)
  const step = computed(() => 10000 / opts.duration)

  let _timer: any = null
  let _throttle: any = null

  function start() {
    clear()
    progress.value = 0
    if (opts.throttle && process.client) {
      _throttle = setTimeout(() => {
        isLoading.value = true
        _startTimer()
      }, opts.throttle)
    } else {
      isLoading.value = true
      _startTimer()
    }
  }

  function finish() {
    progress.value = 100
    _hide()
  }

  function clear() {
    clearInterval(_timer)
    clearTimeout(_throttle)
    _timer = null
    _throttle = null
  }

  function _increase(num: number) {
    progress.value = Math.min(100, progress.value + num)
  }

  function _hide() {
    clear()
    if (process.client) {
      setTimeout(() => {
        isLoading.value = false
        setTimeout(() => { progress.value = 0 }, 400)
      }, 500)
    }
  }

  function _startTimer() {
    if (process.client) {
      _timer = setInterval(() => { _increase(step.value) }, 100)
    }
  }

  return {
    progress,
    isLoading,
    start,
    finish,
    clear
  }
}

const { progress, isLoading, start, finish, clear } = useLoadingIndicator({
  duration: props.duration,
  throttle: props.throttle
})

const customTimeoutToFinish = ref(null)
const clearCustomTimeoutToFinish = () => {
  if (customTimeoutToFinish.value) {
    clearTimeout(customTimeoutToFinish.value)
    customTimeoutToFinish.value = null
  }
}

if (process.client) {
  globalMiddleware.unshift(start)
  router.onError(() => {
    finish()
    clearCustomTimeoutToFinish()
  })

  router.beforeResolve((to, from) => {
    if (!isChangingPage(to, from)) {
      start()
    }
  })

  router.afterEach((_to, _from, failure) => {
    if (failure) {
      finish()
      clearCustomTimeoutToFinish()
    }
  })

  watch(isLoading, () => {
    customTimeoutToFinish.value = setTimeout(() => {
      finish()
    }, props?.duration)
  })

  const unsubPage = nuxtApp.hook('page:finish', () => {finish(), clearCustomTimeoutToFinish()})
  const unsubError = nuxtApp.hook('vue:error', () => {finish(), clearCustomTimeoutToFinish()})

  onBeforeUnmount(() => {
    const index = globalMiddleware.indexOf(start)
    if (index >= 0) {
      globalMiddleware.splice(index, 1)
    }
    unsubPage()
    unsubError()
    clear()
  })
}
</script>

<style module>

.root {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  width: auto;
  height: calc(var(--unit--vertical) / 2);
  background-color: var(--color--gray);
  transform-origin: left;
  transition: transform 0.1s, height 0.4s, opacity 0.4s;
  z-index: 999999;
}
</style>
